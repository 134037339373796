import Head from 'next/head';
import React from 'react';

export const siteName = 'adrianstanek.dev | PWA & Architecture';
export const description = 'Web Development / DevOps / PWA';

const HeadCustom: React.FC = (props) => {
    const { children } = props;

    return (
        <Head>
            <title>adrianstanek.dev | PWA & Architecture</title>
            <meta
                property="description"
                content="Blog über moderne Web Microservice Architekturen und DevOps Kultur."
            />
            <meta
                name="description"
                content="Blog über moderne Web Microservice Architekturen und DevOps Kultur."
            />
            <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
            <meta name="theme-color" content="#002C57" />
            <meta property="og:site_name" content={siteName} />

            <meta name="twitter:card" content="summary" />
            <meta name="twitter:domain" content="adrianstanek.dev" />

            {children}
        </Head>
    );
};

export default HeadCustom;
