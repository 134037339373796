import React from 'react';
import { useRecoilState } from 'recoil';
import Modal from '../../atoms/Modal/Modal';
import subscribeRecoil from './recoil/subscribeRecoil';
import useSubscribe from './hooks/useSubscribe';

const SubscribeModal: React.FC = () => {
    const [subscribeState, setSubscribeState] = useRecoilState(subscribeRecoil);
    const { inProgress, action } = useSubscribe();

    return (
        <>
            <Modal
                show={subscribeState.showModal}
                title="How often do you like to hear from me?"
                closeable
                afterClose={() => {
                    setSubscribeState({ ...subscribeState, showModal: false });
                }}
            >
                <div className="relative flex w-full grid-cols-2 grid-rows-2 flex-col gap-x-8 gap-y-4 px-6 lg:grid">
                    <div className="flex w-full flex-col">
                        <h3 className="my-0 text-lg md:text-xl lg:my-2">Daily</h3>
                        <p>
                            You will receive a daily, in most cases, relatively{' '}
                            <strong>short letter</strong>. <br />
                            <br />
                            You won&apos;t miss anything; the weekly letter is also included here.
                        </p>
                        <div className="buttonPrimary w-full">
                            <button
                                className={`w-full ${inProgress ? 'opacity-70' : ''}`}
                                type="button"
                                disabled={inProgress}
                                onClick={async () => {
                                    await action(subscribeState.email, 'daily');
                                    setSubscribeState({
                                        ...subscribeState,
                                        showModal: false,
                                    });
                                }}
                            >
                                Submit for daily
                            </button>
                        </div>
                    </div>

                    <div className="flex w-full flex-col">
                        <h3 className="my-0 text-lg md:text-xl lg:my-2">Weekly</h3>
                        <p>
                            You will get an <strong>article-length letter</strong> once or twice a
                            week. <br />
                            <br />
                            Including the most significant learnings and experiences, I&apos;ve
                            made.
                        </p>
                        <div className="buttonPrimary w-full">
                            <button
                                className={`w-full ${inProgress ? 'opacity-70' : ''}`}
                                type="button"
                                disabled={inProgress}
                                onClick={async () => {
                                    await action(subscribeState.email, 'weekly');
                                    setSubscribeState({
                                        ...subscribeState,
                                        showModal: false,
                                    });
                                }}
                            >
                                Submit for weekly
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default SubscribeModal;
