import React, { FunctionComponent } from 'react';
import Image from 'next/image';

interface IAvatar {
    // eslint-disable-next-line react/require-default-props
    size?: 'small' | 'large';
    imageStyle?: 'private' | 'business';
}

const Avatar: FunctionComponent<IAvatar> = (props) => {
    const { size, imageStyle } = props;

    return (
        <>
            <div
                className={`relative inline-block overflow-hidden rounded-full shadow-md ${
                    size === 'large' ? 'h-28 w-28' : 'h-14 w-14'
                }`}
            >
                <Image
                    src={
                        imageStyle === 'business'
                            ? '/images/adrianstanek.jpg'
                            : '/images/adrianprivate.jpg'
                    }
                    alt="Adrian Stanek"
                    layout="fill"
                    objectFit="cover"
                    quality={80}
                />
            </div>
        </>
    );
};

Avatar.defaultProps = {
    size: 'large',
    imageStyle: 'business',
};

export default Avatar;
