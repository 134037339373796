import { useCallback, useState } from 'react';
import * as yup from 'yup';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';
import { INewsletterRequest, TListType } from '../../../../../pages/api/newsletter/subscribe';
import subscribeRecoil from '../recoil/subscribeRecoil';

const useSubscribe = () => {
    const [showError, setShowError] = useState(false);
    const [isSent, setIsSent] = useState(false);
    const [inProgress, setInProgress] = useState(false);
    const [subscribeState, setSubscribeState] = useRecoilState(subscribeRecoil);

    const checkMail = useCallback(async (email: string): Promise<boolean> => {
        if (await yup.string().email().required().isValid(email)) {
            return true;
        }

        toast.error('Please enter a valid e-mail address', {
            position: 'top-center',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

        return false;
    }, []);

    const action = useCallback(
        async (email: string, type: TListType) => {
            setInProgress(true);

            if (await yup.string().email().required().isValid(email)) {
                setShowError(false);

                await axios
                    .post('/api/newsletter/subscribe', {
                        email,
                        type,
                    } as INewsletterRequest['body'])
                    .then((r) => {
                        if (r.status === 200) {
                            setIsSent(true);

                            toast.success(
                                "Have successfully subscribed; please confirm the e-mail I've sent to your inbox.",
                                {
                                    position: 'top-center',
                                    autoClose: 4000,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                }
                            );

                            setSubscribeState({ ...subscribeState, email: '' });
                        }
                        setInProgress(false);
                    })
                    .catch(
                        (e: {
                            response: {
                                status: number;
                            };
                        }) => {
                            setIsSent(false);
                            setInProgress(false);

                            let message = '';
                            switch (e.response.status) {
                                case 409:
                                    message = 'E-mail address already exists.';
                                    break;
                                default:
                                    message = 'Error during registration';
                            }

                            toast.error(message, {
                                position: 'top-center',
                                autoClose: 4000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                            });
                        }
                    );
            } else {
                setShowError(true);
                setInProgress(false);
                toast.error('Please enter a valid e-mail address', {
                    position: 'top-center',
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        },
        [setSubscribeState, subscribeState]
    );

    return { action, showError, isSent, inProgress, checkMail };
};

export default useSubscribe;
