import { nanoid } from 'nanoid';
import { atom } from 'recoil';

interface ISubscribeAtom {
    showModal: boolean;
    email: string;
}

const subscribeRecoil = atom<ISubscribeAtom>({
    key: `subscribe-state${nanoid()}`,
    default: {
        showModal: false,
        email: '',
    },
});

export default subscribeRecoil;
