import Link from 'next/link';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPen } from '@fortawesome/free-solid-svg-icons';
import { useRecoilState } from 'recoil';
import useSubscribe from './hooks/useSubscribe';
import SubscribeModal from './SubscribeModal';
import subscribeRecoil from './recoil/subscribeRecoil';

const NewsletterCTA: React.FC = () => {
    const [subscribeState, setSubscribeState] = useRecoilState(subscribeRecoil);

    const { inProgress, showError, isSent, checkMail } = useSubscribe();

    return (
        <>
            <div className="relative mx-auto flex max-w-3xl flex-col text-base">
                <div className="relative z-10 mx-auto flex-col lg:flex lg:items-center">
                    <div className="lg:flex-1">
                        <h2 className="font-serif text-3xl text-secondary sm:text-2xl">
                            Daily/Weekly CTO Newsletter
                        </h2>
                        <p className="text-md mt-3 max-w-3xl text-gray-500">
                            I invite you to my newsletter about the challenges, learnings, success
                            stories, and failures of my everyday experience as CTO.
                        </p>
                    </div>

                    {isSent && (
                        <div className="relative my-4 flex rounded-md bg-green-200 p-4 font-serif text-green-900">
                            Please confirm your email address in your email inbox.
                        </div>
                    )}

                    {!isSent && (
                        <div className="mt-8 flex w-full justify-center lg:mt-2 lg:ml-0">
                            <div className="mt-2 flex w-full max-w-md flex-col">
                                <div className="flex flex-row items-center justify-start gap-0">
                                    <FontAwesomeIcon
                                        icon={faEnvelope}
                                        className="absolute text-neutral-800/20"
                                    />
                                    <input
                                        id="email-address"
                                        name="email-address"
                                        type="email"
                                        autoComplete="email"
                                        onKeyPress={async (event) => {
                                            if (event.key === 'Enter') {
                                                if (await checkMail(subscribeState.email)) {
                                                    setSubscribeState({
                                                        ...subscribeState,
                                                        showModal: true,
                                                    });
                                                }
                                            }
                                        }}
                                        required
                                        className={
                                            showError ? 'icon error form-input' : 'icon form-input'
                                        }
                                        placeholder="Your e-mail"
                                        onChange={(e) =>
                                            setSubscribeState({
                                                ...subscribeState,
                                                email: e.target.value,
                                            })
                                        }
                                        value={subscribeState.email}
                                    />
                                </div>

                                <div className="mt-3 w-full rounded-md shadow">
                                    <button
                                        disabled={inProgress}
                                        onClick={async () => {
                                            if (await checkMail(subscribeState.email)) {
                                                setSubscribeState({
                                                    ...subscribeState,
                                                    showModal: true,
                                                });
                                            }
                                        }}
                                        type="button"
                                        className="flex w-full items-center justify-center rounded-md border border-transparent bg-secondary py-3 px-5 text-base font-medium text-white hover:bg-primary focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    >
                                        <div className="flex flex-row gap-4">
                                            <span>Subscribe daily or weekly</span>
                                            <FontAwesomeIcon
                                                className="relative top-1 mr-2"
                                                icon={faPen}
                                            />
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    <p className="m-auto mt-3 max-w-7xl text-sm text-gray-500 opacity-70">
                        This is how we handle your data{' '}
                        <Link href="/page/privacy" passHref>
                            <span className="cursor-pointer font-medium text-primary">
                                Privacy policy
                            </span>
                        </Link>
                        .{' '}
                        <span>
                            We use rapidmail to send our newsletter. With your registration you
                            agree that the entered data will be transmitted to data will be
                            transmitted to rapidmail. Please note their{' '}
                            <Link href="https://www.rapidmail.de/agb" passHref>
                                <a
                                    href="https://www.rapidmail.de/agb"
                                    className="text-primary"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    General Terms of Business
                                </a>
                            </Link>{' '}
                            and{' '}
                            <Link
                                href="https://www.rapidmail.de/datenschutz-kundenbereich"
                                passHref
                            >
                                <a
                                    href="https://www.rapidmail.de/datenschutz-kundenbereich"
                                    className="text-primary"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Privacy policy
                                </a>
                            </Link>
                            .
                        </span>
                    </p>
                </div>
                <SubscribeModal />
            </div>
        </>
    );
};

export default NewsletterCTA;
